<template>
      <consent
          :locale="language"
          :informationurl="informationUrl"
          :samlurl="samlUrl"
          :orchapi="orchApi"
          :brand="brand"
          :baseUrl="orchApi"
          :authorities="authorities"
      />
</template>

<script>
import {useI18n} from "vue-i18n";
import {getCookie} from "@/utils/useCookie";
import {environment} from "@/config";

export default {
  name: "MyConsent",
  data() {
    return {
      language: this.locale,
      orchApi: environment.VUE_APP_ORCH_API,
      samlUrl: environment.VUE_APP_AUTHORIZATION_URL,
      informationUrl: environment.VUE_APP_INFORMATION_URL,
      brand: process.env.VUE_APP_SUB_PATH,
      authorities: [],
    }
  },
  setup() {
    const {t, locale} = useI18n()
    return {t, locale}
  },
  methods: {
    /**
     * 1: maggiorenne
     * 2: minorenne
     * */
    setUrlPrivacy(){
      const privacy = getCookie('privacy');

      if(this.brand === 'tim'){
        return;
      }

      if(privacy === "2"){
        this.informationUrl = environment.VUE_APP_INFORMATION_URL_2;
      }
    }
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  created() {
    this.setUrlPrivacy();
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mounted(){
    const authorities = getCookie('authorities') || [];
    if (authorities) {
      try {
        this.authorities = JSON.parse(authorities);
        console.log("authorities", this.authorities);
      } catch (error) {
        console.error(error);
      }
    }
  }
}

</script>
